import { Col } from "react-bootstrap";
import navIcon1 from "../assets/img/nav-icon1.svg";

import git from "../assets/img/logo-git.png";

export const Footer = () => {
  return (
    <><section className="footer2" id="footer2"></section>
    <footer className="footer">
      <Col className="text-center ">
        <div className="social-icon">
          <a href="https://www.linkedin.com/in/izaak-aubert-m%C3%A9cibah/" target="blank"><img src={navIcon1} alt="Icon" /></a>
          <a href="https://github.com/IzaakAM" target="blank"><img src={git} alt="Icon" /></a>
        </div>

      </Col>
      <p><a href="tel:+33787220936"> +33 7 87 22 09 36</a> |
      <a href="mailto:aubert.mecibah@gmail.Com"> aubert.mecibah@gmail.com</a></p>
      <p>Izaak Aubert-Mécibah</p>
    </footer></>
  )
}
