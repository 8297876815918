import flutter from "../assets/img/flutter.png";
import react from '../assets/img/react.png';
import en from '../assets/img/en.png';
import web from '../assets/img/web.png';
import c from '../assets/img/c.png';
import java from '../assets/img/Java.png';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import colorSharp from "../assets/img/banner-bg.png"

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 512, min: 0 },
      items: 1
    }
  };

  return (
    <section className="skill" id="skills">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="skill-bx wow zoomIn">
                        <h2>Skills</h2>
                        <p>In this category, I present to you some of my hard-skills in computer development and in English! Regarding the soft-skills, you will be able to appreciate them in interviews!</p>
                        <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                            <div className="item">
                                <img className="logos" src={flutter} alt="flutter logo" />
                                <h5>Flutter & Dart</h5>
                            </div>
                            <div className="item">
                                <img className="logos" src={react} alt="react logo" />
                                <h5>React</h5>
                            </div>
                            <div className="item">
                                <img className="logos" src={web} alt="web logo" />
                                <h5>HTML, CSS, JS</h5>
                            </div>
                            <div className="item">
                                <img className="logos" src={c} alt="c logo" />
                                <h5>C</h5>
                            </div>
                            <div className="item">
                                <img className="logos" src={en} alt="GB logo" />
                                <h5>English - Profesionnal proficiency</h5>
                            </div>
                            <div className="item">
                                <img className="logos" src={java} alt="Java logo" />
                                <h5>JAVA & J2E</h5>
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
        <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
  )
}
