import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";

import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import kakuro from "../assets/img/kakuro.png";
import insa from "../assets/img/INSA.jpg";
import conseil from "../assets/img/conseil.jpg";
import jinnov from "../assets/img/Jinnov.png";
import chatcpt from "../assets/img/ChatCPT.webp";
import projectportfolio2 from "../assets/img/project-portfolio2.png";
import psc1 from "../assets/img/psc1.jpg";
import mooc from "../assets/img/mooc.jpg";
import framatome from "../assets/img/framatome.jpg";

export const Projects = () => {

  const school_projects = [
    {
      title: "Kakuro Master",
      description: "Flutter project - Team of 5 - method Agile. Kakuro Master is a mobile application that allows you to play Kakuro, a logic game similar to Sudoku, with a multiplayer ranking.",
      imgUrl: kakuro,
    },
    {
      title: "Academic Board's member",
      description: "Participation in study councils as an elected student representative: votes on budgets, vacations, number of places admitted.",
      imgUrl: conseil,
      url: "https://www.insa-hautsdefrance.fr/",
    },
    {
      title: "INSA Hauts-de-France - IT and cybersecurity courses (2022-2025)",
      description: "Web and mobile developpement. Cybersecurity, network, cryptography. Functionnal and logical programming.",
      imgUrl: insa,
      url: "https://www.insa-hautsdefrance.fr/",
    },
    
  ];

  const pro_projects = [
    {
      title: "JINNOV",
      description: "JINNOV is the INSA Hauts-de-France Junior and is supervised by the CNJE, which requires Juniors to be extremely rigorous. As event manager, I had to organized events with the help of the communication department and the HR department",
      imgUrl: jinnov,
      url: "https://jinnov-insa.fr/",
    },
    {
      title: "MOOC project management",
      description: "Fundamentals of project management and organization - Start of project - Advanced project management tools - Risk management. ",
      imgUrl: mooc,
      url: "https://mooc.gestiondeprojet.pm/",
    },
    {
      title: "Creating a tracking system - FRAMATOME",
      description: "During my 1 month internship in the 1st year of study, I took the initiative to create this project.",
      imgUrl: framatome,
      url: "https://www.framatome.com/",
    },
    
  ];

  const perso_projects = [
    {
      title: "Chat CPT",
      description: "Technologies: Flutter, Dart, Firebase - Team of 2 - Chat CPT is a humorous application inspired by Chat GPT.",
      imgUrl: chatcpt,
      url: "https://github.com/robjo82/chat_chuispt"
    },
    {
      title: "This super portfolio",
      description: "I made this portfolio using React and Bootstrap with the help of @webdecoded",
      imgUrl: projectportfolio2,

    },
    {
      title: "PSC1",
      description: "Diploma of first aid",
      imgUrl: psc1,
      url: "https://www.protection-civile.org/psc1/"
    },
    
  ];

  return (
    <section className="project" id="project">
      <Container>
        <Row>
          <Col size={12}>

              <div>
                <h2>Projects and experiences</h2>
                <p>In this category, through some of my school, professional and personal experiences, I want to show you that despite the fact that I am a beginner, I am proactive, curious and motivated to learn!</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">School</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Professional</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Personnal</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp">
                    <Tab.Pane eventKey="first">
                      
                      <Row >
                        {
                          school_projects.map((project, index) => {
                              return <ProjectCard key={index} {...project} />;
                          })
                        }
                      </Row>
                      <p>Here are my projects as part of my courses and my involvement in my engineering school.</p>

                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                    <Row>
                    {
                          pro_projects.map((project, index) => {
                              return <ProjectCard key={index} {...project} />;
                          })
                        }
                      </Row>
                      <p>Here are some professional experiences that I could have and which also taught me soft-skills.</p>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                    <Row>
                    {
                          perso_projects.map((project, index) => {
                              return <ProjectCard key={index} {...project} />;
                          })
                        }
                      </Row>
                      <p>Finally, here are some projects from my initiative and my involvement as a citizen!</p>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
